body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e3e3e3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-section{
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 10vh;
  color: #e3e3e3;
  overflow: hidden;
  size: 1.4rem;
}

.nav-section-left {
  display: flex;
  flex-direction: row;
  margin-left: 5%;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  height: 100%;
  min-height: 10vh;
}

.nav-section-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 50%;
  height: 100%;
  min-height: 10vh;
}

.nav-section-link {
  height: 100%;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 10vh;
}

.nav-section-heading {
  color: black;
}

.nav-section-target {
  color: black;
}